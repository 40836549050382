<script>
import mplogo from './assets/mercadopago-logo.webp'
// import amex from './assets/logo_amex.svg'
// import visa from './assets/logo_visa.svg'
// import master from './assets/logo_master.svg'
import pagofacil from './assets/pagofacil.jpg'
import rapipago from './assets/rapipago.jpg'
//import ahora3 from './assets/ahora_3.jpg'
//import ahora6 from './assets/ahora_6.jpg'
//import ahora12 from './assets/ahora_12.png'
//import ahora18 from './assets/ahora_18.png'
//import ahora24 from './assets/ahora_24.png'

export default {
	lang: 'shop',
	data() {
		return {
			mplogo,
			cardsLogos: [pagofacil, rapipago],
		}
	},
}
</script>

<template>
	<CheckoutPaymentCardContent icon="credit-card-outline" :title="$lang('Otros medios de pago.')">
		<v-list class="mt-4">
			<!-- <v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-check</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					{{ '3 cuotas sin interés todos los días, con Tarjetas de Crédito seleccionadas.' | lang }}
				</v-list-item-content>
			</v-list-item> -->
			<!-- <v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-check</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					{{ 'Tarjeta de Crédito' | lang }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-check</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					{{ 'Tarjeta de Débito' | lang }}
				</v-list-item-content>
			</v-list-item> -->
			<!-- <v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-check</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					{{ 'Cuotas sin interés con MercadoPago.' | lang }}
				</v-list-item-content>
			</v-list-item> -->
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-check</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					{{ 'Dinero en cuenta de Mercado Pago.' | lang }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-check</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					{{ 'Cupones de Pago (Pago Fácil, RapiPago)' | lang }}
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<div class="d-flex flex-wrap pt-4">
			<Media max-width="48" max-height="48" :src="mplogo" class="mb-2 mr-2" contain />
			<Media
				v-for="imgUrl in cardsLogos"
				:key="imgUrl"
				:src="imgUrl"
				max-width="48"
				max-height="48"
				class="mb-2 mr-2"
				contain
			/>
		</div>
	</CheckoutPaymentCardContent>
</template>
