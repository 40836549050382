<script>
import { brandsLogos } from './brands-logos'
export default {
	lang: 'shop',
	props: ['order', 'checkout'],
	data() {
		return {
			loading: false,
			dialog: false,
			options: null,
		}
	},
	computed: {
		brandsLogos() {
			return brandsLogos
		},
		validOptions() {
			return this.options?.filter((option) => !!option.creditCardPlans.length) || []
		},
		validBrands() {
			let brands = []
			for (let option of this.validOptions) {
				let brand = option.creditCardBrand
				if (!brands.find((b) => b.code == brand.code)) {
					brands.push(brand)
				}
			}
			return brands.sort((a, b) => {
				if (a.type == b.type) return a.name > b.name ? 1 : -1
				else return a.type == 'credit' ? -1 : 1
			})
		},
	},
	methods: {
		openPaymentOptionsDialog() {
			this.dialog = true
			if (this.options) return
			// this.loadPaymentOptions()
		},
		async loadPaymentOptions() {
			let url = this.checkout
				? `/credit-card/checkout-payment-options/${this.checkout.hash}`
				: `/credit-card/order-payment-options/${this.order.id}`
			await this.$shopApi.get({
				url,
				loading: (v) => (this.loading = v),
				onSuccess: ({ data }) => {
					this.options = data.options
					console.log(this.options)
				},
				onError: (response) => {
					if (this.checkout && this.checkout.catchApiErrors(response)) return
					if (response.message?.code == 'ORDER_NOT_FOUND') {
						this.$router.push({ name: 'user.orders' })
					}
				},
			})
		},
		getBrandLogo(code) {
			return this.brandsLogos[code];
		},
	},
	created() {
		this.loadPaymentOptions()
	}

}
</script>

<template>
	<CheckoutPaymentCardContent :title="$lang('Tarjeta de Crédito / Débito')" icon="credit-card-outline">
		<div class="mt-4">

			<div class="py-2">
				<div v-if="loading" class="pb-4">
					<v-progress-circular :size="35" width="3" color="darkblue" indeterminate></v-progress-circular>
				</div>
				<v-item-group class="d-flex align-sm-center flex-column flex-sm-row flex-sm-wrap"
					style="pointer-events: none;" v-else-if="!loading && validBrands.length">
					<v-item v-slot="{ active, toggle }" v-for="brand in validBrands" :key="brand.id" :value="brand.id">
						<div class="d-flex pr-sm-8 pb-sm-6 pt-sm-0 py-4 brand-item align-center" @click="toggle">
							<div class="brand-item__logo" :class="{ 'elevation-6': active }">
								<Media :src="getBrandLogo(brand.code)" contain height="100%" width="100%" />
							</div>
							<div class="pl-2">
								<div class="font-1 grey--text text--darken-3" :class="{ 'font-weight-bold': active }">
									{{ brand.name }}
								</div>
								<div class="font-0 grey--text">
									{{ brand.type == 'credit' ? $lang('Crédito') : $lang('Débito') }}
								</div>
							</div>
						</div>
					</v-item>
				</v-item-group>
			</div>
			<Button @click.stop="openPaymentOptionsDialog" color="link" small>
				<v-icon small class="mr-1">mdi-credit-card-search</v-icon>
				{{ 'VER PLANES DE PAGO' | lang }}
			</Button>
			<v-dialog v-model="dialog" max-width="600px">
				<v-card :min-height="loading ? null : 500">
					<v-card-title v-if="!loading" class="d-flex align-center">
						<div>{{ 'Planes de pago' | lang }}</div>
						<v-spacer />
						<Button icon small color="grey" @click="dialog = false"><v-icon>mdi-close</v-icon></Button>
					</v-card-title>
					<v-card-text>
						<CreditCardPlanSelector :currency-sign="order.currency.sign" :options="options" :loading="loading"
							:selectable="false" />
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
	</CheckoutPaymentCardContent>
</template>


<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.brand-item {
	cursor: pointer;
	user-select: none;

	@media #{map-get($display-breakpoints, 'xs-only')} {
		&--hasborder {
			border-bottom: 1px solid #ddd;
		}
	}

	.brand-item__logo {
		border: 1px solid rgb(73, 73, 73);
		cursor: pointer;
		padding: 4px;
		border-radius: 8px;
		width: 45px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		justify-items: center;
	}

	&--inactive {
		opacity: 0.8;
	}

	&:hover {
		opacity: 1;

		.brand-item__logo {
			border: 2px solid var(--v-link-lighten2);
		}
	}

	&--active {
		opacity: 1;

		.brand-item__logo {
			border: 2px solid var(--v-link-base) !important;
		}
	}
}
</style>
