<script>
import { brandsLogos } from './brands-logos'

export default {
	lang: 'shop',
	props: {
		options: Array,
		currencySign: String,
		loading: Boolean,
		selectable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			brandId: null,
			entityId: null,
			planId: null,
		}
	},
	computed: {
		brandsLogos() {
			return brandsLogos
		},
		validBrands() {
			let brands = []
			for (let option of this.validOptions) {
				let brand = option.creditCardBrand
				if (!brands.find((b) => b.code == brand.code)) {
					brands.push(brand)
				}
			}
			return brands.sort((a, b) => {
				if (a.type == b.type) return a.name > b.name ? 1 : -1
				else return a.type == 'credit' ? -1 : 1
			})
		},
		/*creditBrands() {
			return this.validBrands.filter((brand) => brand.type == 'credit')
		},
		debitBrands() {
			return this.validBrands.filter((brand) => brand.type == 'debit')
		},*/
		selectedBrand() {
			return this.brandId ? this.validBrands.find((brand) => brand.id == this.brandId) : null
		},
		validOptions() {
			return this.options.filter((option) => !!option.creditCardPlans.length) || []
		},
		brandOptions() {
			return this.selectedBrand
				? this.validOptions.filter((option) => option.creditCardBrand.id == this.selectedBrand.id)
				: []
		},
		entityItems() {
			return this.brandOptions
				.map((option) => option.creditCardEntity)
				.sort((a, b) => {
					if (a.code == 'bank-all') return 1
					if (b.code == 'bank-all') return -1
					return a.displayName > b.displayName ? 1 : -1
				})
		},
		selectedEntity() {
			return this.entityId ? this.entityItems.find((entity) => entity.id == this.entityId) : null
		},
		selectedOption() {
			return this.selectedEntity
				? this.validOptions.find((option) => option.creditCardEntity.id == this.selectedEntity.id)
				: null
		},
		planItems() {
			if (!this.selectedEntity) return []
			let option = this.brandOptions.find((option) => option.creditCardEntity.id == this.selectedEntity.id)
			return option?.creditCardPlans || []
		},
		selectedPlan() {
			return this.planId ? this.planItems.find((plan) => plan.id == this.planId) : null
		},
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
	},
	watch: {
		entityItems(value) {
			this.entityId = null
			if (value.length == 1) {
				this.$nextTick(() => {
					this.entityId = value[0].id
				})
			}
		},
		planItems(value) {
			this.planId = value.length ? value[0].id : null
		},
		brandId() {
			this.onSelectionChanged()
		},
		entityId() {
			this.onSelectionChanged()
		},
		planId() {
			this.onSelectionChanged()
		},
	},
	methods: {
		onSelectionChanged() {
			this.$emit('selection-changed', {
				brand: this.selectedBrand,
				entity: this.selectedEntity,
				plan: this.selectedPlan,
			})
		},
		getPlanCardListeners(plan) {
			if (this.selectable) {
				return { click: () => (this.planId = plan.id) }
			} else {
				return {}
			}
		},
	},
}
</script>

<template>
	<div class="pa-10" v-if="loading">
		<div class="font-2 pa-4 text-center">{{ 'Cargando planes de pago' | lang }}</div>
		<v-progress-linear indeterminate color="success" />
	</div>
	<div v-else>
		<!-- BRAND SELECTORS -->
		<div class="pt-3 pb-3 pb-sm-0">
			<div class="font-1 pb-3 pb-sm-6 d-flex align-center">
				<v-badge inline color="success" content="1" class="mr-1" />
				<div>{{ 'Seleccioná tu Tarjeta' | lang }}</div>
			</div>
			<v-item-group
				v-model="brandId"
				class="d-flex align-sm-center flex-column flex-sm-row flex-sm-wrap"
				:mandatory="!!brandId"
			>
				<v-item
					v-slot="{ active, toggle }"
					v-for="(brand, i) in validBrands"
					:key="brand.id"
					:value="brand.id"
				>
					<div
						class="d-flex pr-sm-8 pb-sm-6 pt-sm-0 py-4 brand-item align-center"
						@click="toggle"
						:class="{
							'brand-item--active': active,
							'brand-item--inactive': !active && brandId,
							'brand-item--hasborder': i < validBrands.length - 1,
						}"
					>
						<div class="brand-item__logo" :class="{ 'elevation-6': active }">
							<Media :src="brandsLogos[brand.code]" contain height="100%" width="100%" />
						</div>
						<div class="pl-2">
							<div class="font-1 grey--text text--darken-3" :class="{ 'font-weight-bold': active }">
								{{ brand.name }}
							</div>
							<div class="font-0 grey--text">
								{{ brand.type == 'credit' ? $lang('Crédito') : $lang('Débito') }}
							</div>
						</div>
					</div>
				</v-item>
			</v-item-group>
		</div>
		<!-- ENTITY SELECTORS -->
		<div class="pb-3" v-if="entityItems.length">
			<div class="font-1 pb-3 d-flex align-center">
				<v-badge inline color="success" content="2" class="mr-1" />
				<div>{{ 'Seleccioná el Banco o Entidad emisora de tu Tarjeta' | lang }}</div>
			</div>
			<v-radio-group v-model="entityId" class="mt-0" hide-details :mandatory="!!entityId">
				<v-card
					v-for="entity of entityItems"
					:key="entity.id"
					:value="entity.id"
					class="my-2 pa-2"
					@click="entityId = entity.id"
				>
					<v-radio :value="entity.id" :label="entity.displayName" class="mb-0" />
				</v-card>
			</v-radio-group>
		</div>
		<!-- PLAN SELECTORS -->
		<div class="py-3" v-if="planItems.length">
			<div class="font-1 pb-3 d-flex align-center">
				<v-badge inline color="success" content="3" class="mr-1" />
				<div v-if="selectable">{{ 'Seleccioná el plan de pago' | lang }}</div>
				<div v-else>{{ 'Planes de pago disponibles' | lang }}</div>
			</div>
			<v-radio-group v-model="planId" class="mt-0" hide-details mandatory>
				<v-card
					v-for="plan in planItems"
					:key="plan.id"
					class="my-2 pa-2"
					v-on="getPlanCardListeners(plan)"
				>
					<div class="d-flex align-center">
						<v-radio :value="plan.id" label="" class="mb-0" v-if="selectable" />
						<div class="d-flex w100 align-center flex-wrap">
							<div>
								<b class="nowrap">
									{{ selectedBrand.type == 'debit' ? $lang('1 pago de') : `${plan.qty}x` }}
									<PriceText
										:amount="plan.calc.installmentsAmount"
										:currency="currencySign"
										:sup-decimals="false"
									/>
								</b>
							</div>
							<div
								v-if="plan.specialPlanName || plan.specialPlanLogo"
								class="d-flex align-center ml-2"
							>
								<img
									v-if="plan.specialPlanLogo"
									style="max-height: 30px; width: auto; max-width: 80px"
									:src="plan.specialPlanLogo"
									contain
								/>
								<div
									v-if="plan.specialPlanName"
									class="font-0 grey--text text--darken-2 font-weight-bold"
									:class="{ 'ml-1': !!plan.specialPlanLogo }"
								>
									{{ plan.specialPlanName }}
								</div>
							</div>
							<v-spacer :class="{ w100: isMobile }" />
							<div class="success--text font-1" v-if="plan.qty > 1 && !plan.surcharge">
								{{ 'Sin Interés' | lang }}
							</div>
							<div v-else-if="plan.qty > 1" class="grey--text font-1">
								{{ 'Total' | lang }}
								<PriceText
									:amount="plan.calc.totalAmount"
									:currency="currencySign"
									:sup-decimals="false"
								/>
							</div>
						</div>
					</div>
				</v-card>
			</v-radio-group>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.brand-item {
	cursor: pointer;
	user-select: none;

	@media #{map-get($display-breakpoints, 'xs-only')} {
		&--hasborder {
			border-bottom: 1px solid #ddd;
		}
	}

	.brand-item__logo {
		border: 2px solid var(--v-link-lighten4);
		cursor: pointer;
		padding: 4px;
		border-radius: 8px;
		width: 45px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		justify-items: center;
	}

	&--inactive {
		opacity: 0.8;
	}
	&:hover {
		opacity: 1;
		.brand-item__logo {
			border: 2px solid var(--v-link-lighten2);
		}
	}
	&--active {
		opacity: 1;
		.brand-item__logo {
			border: 2px solid var(--v-link-base) !important;
		}
	}
}
</style>
