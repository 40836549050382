<script>
import { get } from 'vuex-pathify'

export default {
	lang: 'shop',
	name: 'CheckoutLayout',
	metaInfo() {
		return {
			title: `${this.title} | Checkout`,
		}
	},
	pageView() {
		let url = this.$router.resolve({
			name: this.$route.name,
			params: {
				...this.$route.params,
				hash: '__HASH__',
			},
			query: this.$route.query,
		}).href
		return {
			location: url,
		}
	},
	props: {
		step: String,
		title: String,
		payload: Object,
		showMobileExpandedSummary: Boolean,
	},
	data() {
		return {
			loadingStep: false,
			loadingFull: false,
			stepData: {},
		}
	},
	computed: {
		order: get('checkout/order'),
		hash: get('route@params.hash'),
		queryEdit: get('route@query.edit'),
		stepSlotData() {
			return {
				...this.stepData,
				order: this.order,
				sendPayload: this.sendPayload.bind(this),
				genStepRoute: this.genStepRoute.bind(this),
				loading: this.loadingFull,
				setLoading: (v) => (this.loadingFull = v),
				checkoutHash: this.hash,
			}
		},
		loadingSummary() {
			return !this.order
		},
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
	methods: {
		updateCheckout(checkout) {
			let { order, empty, missingStep, autoModified } = checkout
			if (empty) {
				this.$router.push({ name: 'home' })
			} else if (missingStep) {
				this.$router.push(this.genStepRoute(missingStep))
			} else {
				if (autoModified) {
					//console.log('AUTO_MODIFIED')
				}
				this.$store.set('checkout/order', order)
			}
		},

		initCheckout() {
			this.loadingStep = true
			this.$store.set('checkout/order', null)
			this.$shopApi.get({
				url: '/checkout/init',
				done: ({ success, data }) => {
					this.updateCheckout(data.checkout)
					if (success) {
						let { hash } = data.checkout
						this.$router.replace(this.genStepRoute(data.nextStep, { hash }))
					}
				},
			})
		},

		loadStep() {
			this.$shopApi.get({
				url: `/checkout/step/${this.step}`,
				query: { hash: this.hash },
				loading: (v) => (this.loadingStep = v),
				done: ({ success, data }) => {
					this.updateCheckout(data.checkout)
					if (data.nextStep) {
						return this.$router.replace(this.genStepRoute(data.nextStep))
					}
					if (success) {
						this.assignStepData(data)
						this.$emit('stepLoaded', data)
					}
				},
			})
		},
		async sendPayload(payload) {
			payload = payload || this.payload || {}
			return await this.$shopApi.post({
				url: `/checkout/step/${this.step}`,
				query: { hash: this.hash },
				data: payload,
				loading: (v) => (this.loadingFull = v),
				onValidation: ({ validation }) => {
					this.$emit('payloadValidation', validation)
				},
				done: ({ success, validation, message, data }) => {
					if (data.checkout) this.updateCheckout(data.checkout)
					this.$emit('payloadResponse', { success, validation, message, data })
					if (success && data.nextStep) {
						let nextRoute = this.genStepRoute(data.nextStep)
						if (data.jumpStep) this.$router.replace(nextRoute)
						else this.$router.push(nextRoute)
					}
				},
			})
		},
		genStepRoute(stepKey, opt = {}) {
			let { edit, hash } = opt
			if (!hash) hash = this.hash

			let query = undefined
			if (edit && !this.queryEdit) query = { edit: 1 }
			if (this.queryEdit) stepKey = 'confirm'

			return {
				name: `checkout.${stepKey}`,
				params: { hash },
				query,
			}
		},
		assignStepData(key, value) {
			if (value === undefined) {
				for (let [k, v] of Object.entries(key)) {
					this.assignStepData(k, v)
				}
			} else {
				this.$set(this.stepData, key, value)
			}
		},
		summaryCtaClick() {
			this.sendPayload()
		},
	},
	created() {
		if (this.step) this.loadStep()
		else this.initCheckout()
	},
}
</script>

<template>
	<div>
		<Container class="pb-16 pt-8" :max-width="{ xs: '100%', sm: '90%' }">
			<v-row>
				<v-col cols="12" md="7" class="pt-5">
					<div class="mb-4" v-if="loadingStep">
						<v-skeleton-loader loading type="heading" />
					</div>
					<div class="font-4 mb-4" v-else>
						{{ title }}
					</div>
					<v-divider class="mb-4" />
					<div v-if="loadingStep">
						<v-card tile class="mb-4 pa-5">
							<v-skeleton-loader loading type="heading" class="mb-6" />
							<v-skeleton-loader loading type="list-item-avatar" />
							<v-skeleton-loader loading type="list-item-avatar" />
						</v-card>
					</div>
					<slot v-else name="step" v-bind="stepSlotData"></slot>
				</v-col>
				<v-col cols="12" md="5" class="pt-5">
					<div v-if="loadingSummary || loadingStep">
						<v-skeleton-loader loading type="heading" class="mb-6" />
						<v-card tile class="mb-4 pa-5">
							<v-skeleton-loader loading type="heading" class="mb-6" />
							<v-skeleton-loader loading type="list-item-avatar@6" />
						</v-card>
					</div>

					<template v-else>
						<div class="font-3 mb-4" v-if="!isMobile || showMobileExpandedSummary">
							{{ 'Resumen de compra' | lang }}
						</div>
						<v-divider class="mb-4" v-if="!isMobile || showMobileExpandedSummary" />
						<OrderSummary v-if="!isMobile || showMobileExpandedSummary" :order="order" />
						<OrderSummaryMobile v-else :order="order" />
						<slot name="below-summary" v-bind="stepSlotData" />
					</template>
				</v-col>
			</v-row>
		</Container>
		<v-overlay :value="loadingFull" z-index="999999">
			<v-progress-circular indeterminate size="80" />
		</v-overlay>
	</div>
</template>
