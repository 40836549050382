<script>
import { componentExists } from '@/site/autoloader'

export default {
	lang: 'shop',
	inject: ['xcheckout'],
	computed: {
		checkout() {
			return this.xcheckout()
		},
		paymentMethods() {
			return [...(this.checkout.stepViewData?.paymentMethods || [])].sort((a, b) => {
				if (a.methodKey === 'CreditCard') return -1
				if (b.methodKey === 'CreditCard') return 1
				return 0
			})
		}
	},
	methods: {
		compName(method) {
			return `${method.methodKey}-Payment-CheckoutOptions`
		},
		compExists(method) {
			return componentExists(this.compName(method))
		},
	},
}
</script>

<template>
	<CheckoutStepLayout :title="$lang('Elige la forma de pago')">
		<div v-for="(method, i) in paymentMethods" :key="i">
			<component v-if="compExists(method)" :is="compName(method)" :data="method.checkoutData"
				@optionSelected="checkout.stepSubmit({ methodKey: method.methodKey, input: $event })" />
		</div>
	</CheckoutStepLayout>
</template>

<style></style>
