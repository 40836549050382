<script>
export default {
	lang: 'shop',
	data() {
		return {
			logo: this.$srv('CheckoutAppBar.logo'),
			backColor: this.$srv('CheckoutAppBar.backColor'),
			textColor: this.$srv('CheckoutAppBar.textColor'),
		}
	},
	methods: {
		keepBuying() {
			this.$shop.goShop()
		},
	},
	mounted() {
		this.$vuetify.breakpoint.smAndDown && window.toggleChat?.(false)
	},
	beforeDestroy() {
		this.$vuetify.breakpoint.smAndDown && window.toggleChat?.(true)
	},
}
</script>

<template>
	<v-app>
		<div :style="{ 'background-color': backColor, 'z-index': 1, height: '60px' }" class="elevation-4">
			<Container class="d-flex align-center h100">
				<div class="h100" style="position: relative">
					<img :src="logo" style="height: 80px; width: auto; position: absolute; top: -5px" />
				</div>
				<v-spacer />
				<Button text :color="textColor" small @click="keepBuying">
					<div class="preline">
						{{ $vuetify.breakpoint.xs ? $langText('Seguir\nComprando') : $lang('Seguir Comprando') }}
					</div>
				</Button>
			</Container>
		</div>
		<v-main class="grey lighten-3 pt-2">
			<router-view />
		</v-main>
	</v-app>
</template>
